<template>
  <div class="content has-text-justified">
    <h1><strong>Thank You!</strong></h1>

    <p>Thank you for using ReChess! I hope you enjoyed this site.</p>
    <p>This project started as a bachelor's thesis and as an excuse to learn Vue.js. It has been a fun journey, but
      unfortunately the hosting costs don't allow me to keep it running indefinitely.</p>
    <p>Feel free to fork the project <a href="https://github.com/pol-rivero/ReChess">on GitHub</a> if you want to keep
      using it. There are instructions available on the README for setting it up locally and deploying it to Firebase.
    </p>
  </div>
</template>
